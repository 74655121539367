import { Injectable } from '@angular/core';
import { ServicesService } from '../services.service';
import { CognitoIdentityServiceProvider, config } from 'aws-sdk';
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
  CognitoUserAttribute,
} from 'amazon-cognito-identity-js';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { TZone } from 'moment-timezone-picker';
import * as momentZone from 'moment-timezone';
var AmazonCognitoIdentity = require('amazon-cognito-identity-js');

const SES = new AWS.SES();
var s3 = new AWS.S3();
let poolData = {
  UserPoolId: 'us-east-2_h8VcL2Tg6',
  ClientId: '61u543sdf10u7kpq7ntfqf82rt',
};
import { environment } from 'src/environments/environment';
import * as AWS from 'aws-sdk';
import { CryptoService } from '../services/crypto/crypto.service';
config.update({
  secretAccessKey: environment.ACCESS_KEY,
  accessKeyId: environment.KEY_ID,
  region: 'us-east-2',
  correctClockSkew: true,
});
let userPool = new CognitoUserPool(poolData);
@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  cognitoUser: any;
  constructor(
    public service: ServicesService,
    public router: Router,
    public spinner: NgxSpinnerService,
    private crypto: CryptoService,
  ) {
    config.update({
      secretAccessKey: this.crypto.dec(environment.ACCESS_KEY),
      accessKeyId: this.crypto.dec(environment.KEY_ID),
      region: 'us-east-2',
      correctClockSkew: true,
    });
    poolData = {
      UserPoolId: this.crypto.dec(environment.UserPoolId),
      ClientId: this.crypto.dec(environment.ClientId),
    };

    userPool = new CognitoUserPool(poolData);
  }
  async signIn(email, password) {
    const authenticationData = {
      Username: email,
      Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);
    const userData = {
      Username: email,
      Pool: userPool,
    };
    await this.ResetMFASetup(email, password);
    let me = this;
    const cognitoUser = new CognitoUser(userData);
    return new Promise((resolve) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {
          resolve([
            { message: 'Login Successfully', StatusCode: 1, Data: result },
          ]);
        },
        onFailure: function (err) {
          resolve([{ message: err.message, StatusCode: 0, Data: '' }]);
        },
        newPasswordRequired: (userAttributes) => {
          this.spinner.hide();
          var data = btoa(JSON.stringify(email));
          this.router.navigate([`/Changepassword/${data}`]);
        },
        totpRequired: (totpRequir, test) => {},
      });
    });
  }
  async verifyUserFilter(Email, password, type) {
    let me = this;
    await this.service.versions_getUserByEmail(Email).then(async (user) => {
      if (user[0].status == 200) {
        var list = JSON.parse(<string>user[0].json);

        if (list.data[0].roll_id == 1 || list.data[0].roll_id == 5) {
          await this.spinner.hide();
          window.localStorage.setItem('adminUser', Email);
          window.sessionStorage.setItem('b6h3a5d9r6e3s5h', list.data[0].id);
          var userdata = {
            email: Email,
            password: password,
          };
          var datas = this.crypto.enc(JSON.stringify(userdata));
          if (type == 'totpRequired')
            me.router.navigate([`verifyauthy/${datas}`]);
          else me.router.navigate([`authypassword/${datas}`]);
        } else {
          await this.spinner.hide();
          this.service.ShowError('Incorrect username or password.');
        }
      } else {
        await this.spinner.hide();
        this.service.ShowSuccess(user[0].message);
      }
    });
  }
  async TempsignIn(email, password, Newpassword) {
    const authenticationData = {
      Username: email,
      Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);
    const userData = {
      Username: email,
      Pool: userPool,
    };
    const cognitoUser = new CognitoUser(userData);
    return new Promise((resolve) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {
          resolve([
            { message: 'Login Successfully', StatusCode: 1, Data: result },
          ]);
        },
        onFailure: function (err) {
          resolve([{ message: err.message, StatusCode: 0, Data: '' }]);
        },
        newPasswordRequired: (userAttributes) => {
          delete userAttributes.email_verified;
          cognitoUser.completeNewPasswordChallenge(
            Newpassword,
            userAttributes,
            {
              onSuccess: function (result) {
                resolve([
                  {
                    message: 'Change password successfully!',
                    StatusCode: 2,
                    Data: result,
                  },
                ]);
              },
              onFailure: function (err) {
                resolve([{ message: err.message, StatusCode: 0, Data: '' }]);
              },
            },
          );
        },
      });
    });
  }

  isLoggedIn() {
    var login = window.sessionStorage.getItem('logintrue');
    if (!login) {
      this.router.navigate(['/login']);
      return;
    } else {
      return true;
    }
  }

  getAuthenticatedUser() {
    // gets the current user from the local storage
    return userPool.getCurrentUser();
  }
  redirectlogin() {
    return new Promise((resolve) => {
      resolve(userPool.getCurrentUser());
    });
  }

  logOut() {
    return this.getAuthenticatedUser().signOut();
  }
  changepass(email, newPassword, oldPassword) {
    const cognitoUser = userPool.getCurrentUser();
    cognitoUser.getUsername();
    return new Promise((resolve) => {
      userPool
        .getCurrentUser()
        .changePassword(oldPassword, newPassword, function (err, result) {
          return err;
        });
    });
  }

  deleteuser(email) {
    const user = {
      Username: email,

      UserPoolId: userPool.getUserPoolId(),
    };
    config.update({
      secretAccessKey: this.crypto.dec(environment.ACCESS_KEY),
      accessKeyId: this.crypto.dec(environment.KEY_ID),
      region: 'us-east-2',
      correctClockSkew: true,
    });
    const cognito = new CognitoIdentityServiceProvider();
    return new Promise((resolve, reject) => {
      cognito.adminDeleteUser(user, (err, data) => {
        if (err) {
          resolve([{ message: err.message, StatusCode: 0, Data: err }]);
        } else {
          resolve([
            {
              message: 'User deleted successfully !',
              StatusCode: 1,
              Data: data,
            },
          ]);
        }
      });
    });
  }
  CreateUser(email) {
    const attributeList = [];
    attributeList.push(
      new CognitoUserAttribute({
        Name: 'email',
        Value: email,
      }),
    );
    attributeList.push(
      new CognitoUserAttribute({
        Name: 'email_verified',
        Value: 'true',
      }),
    );
    const user = {
      Username: email,
      UserPoolId: userPool.getUserPoolId(),
      UserAttributes: attributeList,
    };
    config.update({
      secretAccessKey: this.crypto.dec(environment.ACCESS_KEY),
      accessKeyId: this.crypto.dec(environment.KEY_ID),
      region: 'us-east-2',
      correctClockSkew: true,
    });
    const cognito = new CognitoIdentityServiceProvider();

    return new Promise((resolve, reject) => {
      cognito.adminCreateUser(user, (err, data) => {
        if (err) {
          resolve([{ message: err.message, StatusCode: 0, Data: err }]);
        } else {
          resolve([
            {
              message: 'Student Add successfully !',
              StatusCode: 1,
              Data: data,
            },
          ]);
        }
      });
    });
  }
  forcechnagepass(email) {
    const user = {
      Username: email,
      UserPoolId: userPool.getUserPoolId(),
    };
    config.update({
      secretAccessKey: this.crypto.dec(environment.ACCESS_KEY),
      accessKeyId: this.crypto.dec(environment.KEY_ID),
      region: 'us-east-2',
      correctClockSkew: true,
    });
    const cognito = new CognitoIdentityServiceProvider();
    return new Promise((resolve, reject) => {
      cognito.adminResetUserPassword(user, (err, data) => {
        if (err) {
          resolve([{ message: err.message, StatusCode: 0, Data: err }]);
        } else {
          resolve([
            {
              message: 'Force password change apply',
              StatusCode: 1,
              Data: data,
            },
          ]);
        }
      });
    });
  }
  blockuserdis(email) {
    const user = {
      Username: email,
      UserPoolId: userPool.getUserPoolId(),
    };
    config.update({
      secretAccessKey: this.crypto.dec(environment.ACCESS_KEY),
      accessKeyId: this.crypto.dec(environment.KEY_ID),
      region: 'us-east-2',
      correctClockSkew: true,
    });
    const cognito = new CognitoIdentityServiceProvider();
    return new Promise((resolve, reject) => {
      cognito.adminDisableUser(user, (err, data) => {
        if (err) {
          resolve([
            {
              message: 'User disabled unsuccessfully !',
              StatusCode: 0,
              Data: err,
            },
          ]);
        } else {
          resolve([
            {
              message: 'User disabled successfully !',
              StatusCode: 1,
              Data: data,
            },
          ]);
        }
      });
    });
  }
  adduserdis(email) {
    const user = {
      Username: email,
      UserPoolId: userPool.getUserPoolId(),
    };
    config.update({
      secretAccessKey: this.crypto.dec(environment.ACCESS_KEY),
      accessKeyId: this.crypto.dec(environment.KEY_ID),
      region: 'us-east-2',
      correctClockSkew: true,
    });
    const cognito = new CognitoIdentityServiceProvider();
    return new Promise((resolve, reject) => {
      cognito.adminDisableUser(user, (err, data) => {
        if (err) {
          resolve([
            {
              message: 'User disabled unsuccessfully !',
              StatusCode: 0,
              Data: err,
            },
          ]);
        } else {
          resolve([
            {
              message: 'User disabled successfully !',
              StatusCode: 1,
              Data: data,
            },
          ]);
        }
      });
    });
  }

  register(email, password) {
    const attributeList = [];
    attributeList.push(
      new CognitoUserAttribute({
        Name: 'email',
        Value: email,
      }),
    );
    return new Promise((resolve) => {
      userPool.signUp(email, password, attributeList, null, (err, result) => {
        if (err) {
          resolve([{ message: err.message, StatusCode: 0, Data: '' }]);
        } else {
          resolve([
            {
              message: 'Register Successfully',
              StatusCode: 1,
              Data: result.user,
            },
          ]);
        }
      });
    });
  }
  sendmail(data) {
    const params = {
      Destination: {
        ToAddresses: [data.to],
        CcAddresses: [data.cc],
      },
      Message: {
        Body: {
          Html: {
            Charset: 'UTF-8',
            Data: data.text,
          },
        },
        Subject: { Charset: 'UTF-8', Data: data.subject },
      },
      Source: 'Destination Certification <system@destcert.com>',
      ConfigurationSetName: 'DestCert',
    };

    return new Promise((resolve, reject) => {
      SES.sendEmail(params, (err, data) => {
        if (err) {
          resolve([{ message: err.message, StatusCode: 0 }]);
        } else {
          resolve([{ message: 'Mail Send Successfully', StatusCode: 1 }]);
        }
      });
    });
  }
  GetAllBeeFreeTemplate() {
    const SES1 = new AWS.SES({ region: 'us-east-1' });
    var params = {
      MaxItems: 100,
    };
    return new Promise((resolve, reject) => {
      SES1.listTemplates(params, (err, data) => {
        if (err) {
          resolve([{ message: err.message, StatusCode: 0 }]);
        } else {
          resolve([{ data: data, StatusCode: 1 }]);
        }
      });
    });
  }
  GetBeeFreeTemplate(pStrTemplateName: any) {
    const SES1 = new AWS.SES({ region: 'us-east-1' });
    var params = {
      TemplateName: pStrTemplateName,
    };
    return new Promise((resolve, reject) => {
      SES1.getTemplate(params, (err, data) => {
        if (err) {
          resolve([{ message: err.message, StatusCode: 0 }]);
        } else {
          resolve([{ data: data, StatusCode: 1 }]);
        }
      });
    });
  }
  TemplateSendMail(data) {
    let defaultParams = {
      subject: data.subject,
      UserName: data.name,
      Link: data.link,
      Cohort_name: data.cohort_name,
    };
    const params = {
      Source: 'Destination Certification <system@destcert.com>',
      Destination: { ToAddresses: [data.to] },
      Template: data.Template,
      TemplateData: JSON.stringify(defaultParams),
    };
    const SES1 = new AWS.SES({ region: 'us-east-1' });
    return new Promise((resolve, reject) => {
      SES1.sendTemplatedEmail(params, (err, data) => {
        if (err) {
          resolve([{ message: err.message, StatusCode: 0 }]);
        } else {
          resolve([{ message: 'Mail Send Successfully', StatusCode: 1 }]);
        }
      });
    });
  }
  verifyDKIM(domain) {
    const SES1 = new AWS.SES({ region: 'us-east-1' });
    let verify = {
      Domain: domain,
    };
    return new Promise((resolve, reject) => {
      SES1.verifyDomainDkim(verify, (err, data) => {
        if (err) {
          resolve([{ message: err.message, StatusCode: 0 }]);
        } else {
          resolve([{ message: 'DKIM verification successful', StatusCode: 1 }]);
        }
      });
    });
  }
  async uploadFiles(data) {
    const base64Data = data.replace(/^data:(image|text)\/\w+;base64,/, '');
    const type = data.split(';')[0].split('/')[1];
    var chars =
      '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let ids = '';
    for (let id = 0; id < 20; id++) {
      ids += chars[Math.floor(Math.random() * chars.length)];
    }
    var s3Params_logo = {
      Bucket: 'cisspupload',
      Key: ids + '.' + (type == 'plain' ? 'txt' : type),
      Body: base64Data,
      ContentEncoding: 'base64',
      ACL: 'public-read',
    };

    const img = await s3.upload(s3Params_logo).promise();
    return img.Location;
  }
  ResendPasswordTemp(email) {
    const attributeList = [];
    attributeList.push(
      new CognitoUserAttribute({
        Name: 'email',
        Value: email,
      }),
    );
    attributeList.push(
      new CognitoUserAttribute({
        Name: 'email_verified',
        Value: 'true',
      }),
    );
    const user = {
      Username: email,
      UserPoolId: userPool.getUserPoolId(),
      UserAttributes: attributeList,
      MessageAction: 'RESEND',
    };
    config.update({
      secretAccessKey: this.crypto.dec(environment.ACCESS_KEY),
      accessKeyId: this.crypto.dec(environment.KEY_ID),
      region: 'us-east-2',
      correctClockSkew: true,
    });
    const cognito = new CognitoIdentityServiceProvider();
    return new Promise((resolve, reject) => {
      cognito.adminCreateUser(user, (err, data) => {
        if (err) {
          resolve([{ message: err.message, StatusCode: 0, Data: err }]);
        } else {
          resolve([
            {
              message: 'Password resend successfully!',
              StatusCode: 1,
              Data: data,
            },
          ]);
        }
      });
    });
  }

  async Getqrcode(email, password) {
    return new Promise(async (resolve) => {
      var authenticationData = {
        Username: email,
        Password: password,
      };
      var authenticationDetails =
        new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);
      var userData = {
        Username: email,
        Pool: userPool,
      };
      var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {
          var accessToken = result.getAccessToken().getJwtToken();
          cognitoUser.associateSoftwareToken(this);
        },

        onFailure: function (err) {
          resolve([
            { message: err.message, StatusCode: 0, Data: err, code: err },
          ]);
        },
        associateSecretCode: function (secretCode) {
          const str =
            'otpauth://totp/Destination%20Certification' +
            '' +
            '?secret=' +
            secretCode +
            '&issuer=';
          resolve([
            {
              message: '',
              StatusCode: 1,
              Data: this,
              code: str,
              devicecode: secretCode,
            },
          ]);
        },
      });
    });
  }
  verify(email, password, code, dataset) {
    let me = this;
    return new Promise(async (resolve) => {
      var authenticationData = {
        Username: email,
        Password: password,
      };
      var authenticationDetails =
        new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);
      var userData = {
        Username: email,
        Pool: userPool,
      };
      var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
      await cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {
          me.MFASetup(email, password);
          cognitoUser.verifySoftwareToken(code, 'My TOTP device', {
            onSuccess: function (success) {
              resolve([{ message: success, StatusCode: 1 }]);
            },
            onFailure: function (errcode) {
              resolve([{ message: errcode.message, StatusCode: 0 }]);
            },
          });
        },
        onFailure: function (err) {
          resolve([{ message: err.message, StatusCode: 0 }]);
        },
        totpRequired: (totpRequir, test) => {
          cognitoUser.sendMFACode(
            code,
            {
              onFailure: function (err: { message: any }) {
                resolve([{ message: err.message, StatusCode: 0, Data: '' }]);
              },
              onSuccess: function (res) {
                resolve([{ message: 'res.message', StatusCode: 1, Data: '' }]);
              },
            },
            'SOFTWARE_TOKEN_MFA',
          );
        },
      });
    });
  }
  async MFASetup(email, password) {
    config.update({
      secretAccessKey: this.crypto.dec(environment.ACCESS_KEY),
      accessKeyId: this.crypto.dec(environment.KEY_ID),
      region: 'us-east-2',
      correctClockSkew: true,
    });
    const cognito = new AWS.CognitoIdentityServiceProvider();
    return new Promise(async (resolve) => {
      var aaa = await cognito.adminInitiateAuth(
        {
          AuthFlow: 'ADMIN_NO_SRP_AUTH',
          ClientId: poolData.ClientId,
          UserPoolId: userPool.getUserPoolId(),
          AuthParameters: {
            USERNAME: email,
            PASSWORD: password,
            DEVICE_KEY: 'TOTP',
          },
        },
        (err, data) => {},
      );

      var aaa2 = await cognito.adminSetUserMFAPreference(
        {
          UserPoolId: poolData.UserPoolId,
          Username: email,
          SoftwareTokenMfaSettings: {
            Enabled: true,
            PreferredMfa: true,
          },
        },
        (err, data) => {},
      );

      var aaa1 = await cognito.adminGetUser(
        {
          UserPoolId: poolData.UserPoolId,
          Username: email,
        },
        (err, data) => {},
      );
    });
  }
  async ResetMFASetup(email, password) {
    config.update({
      secretAccessKey: this.crypto.dec(environment.ACCESS_KEY),
      accessKeyId: this.crypto.dec(environment.KEY_ID),
      region: 'us-east-2',
      correctClockSkew: true,
    });
    var cognito = new CognitoIdentityServiceProvider();
    await cognito
      .adminSetUserMFAPreference({
        UserPoolId: poolData.UserPoolId,
        Username: email,
        SoftwareTokenMfaSettings: {
          Enabled: false,
        },
      })
      .promise();
  }
  async forgotPassword(email) {
    const user = {
      Username: email,
      Pool: userPool,
    };
    return new Promise((resolve) => {
      const cognitoUser = new CognitoUser(user);
      cognitoUser.forgotPassword({
        onSuccess: function (result) {
          resolve([
            {
              message: 'Please check your email for Authentication code.',
              StatusCode: 1,
              Data: result,
            },
          ]);
        },
        onFailure: function (err) {
          resolve([{ message: err.message, StatusCode: 0, Data: '' }]);
        },
      });
    });
  }

  forgotPasswordAuthcode(email, code, password) {
    const user = {
      Username: email,
      Pool: userPool,
    };
    return new Promise((resolve) => {
      const cognitoUser = new CognitoUser(user);
      cognitoUser.confirmPassword(code, password, {
        onSuccess: function () {
          resolve([
            { message: 'Password changed successfully.', StatusCode: 1 },
          ]);
        },
        onFailure: function (err) {
          resolve([{ message: err.message, StatusCode: 0 }]);
        },
      });
    });
  }
  resendConfirmationCode(email) {
    const user = {
      Username: email,
      Pool: userPool,
    };
    return new Promise((resolve) => {
      const cognitoUser = new CognitoUser(user);
      cognitoUser.forgotPassword({
        onSuccess: function (result) {
          resolve([
            {
              message: 'Resend code successfully.',
              StatusCode: 1,
              Data: result,
            },
          ]);
        },
        onFailure: function (err) {
          resolve([{ message: err.message, StatusCode: 0, Data: '' }]);
        },
      });
    });
  }
  formatZone(zone: string): TZone {
    const utc: string = momentZone.tz(zone).format('Z');
    const abbr: string = momentZone.tz(zone).zoneAbbr();
    return {
      name: `${zone} (${utc})`,
      nameValue: zone,
      timeValue: utc,
      group: zone.split('/', 1)[0],
      abbr: abbr,
    };
  }
  currentTimeZone() {
    return this.formatZone(momentZone.tz.guess(true));
  }
  ConvertFormatDbDateTime(
    pStrDate:
      | momentZone.Moment
      | Date
      | string
      | number
      | (number | string)[]
      | momentZone.MomentInputObject
      | null
      | undefined,
    pStrTimeZone: string,
  ) {
    momentZone.tz.setDefault(pStrTimeZone);
    return momentZone(pStrDate).tz(pStrTimeZone).format();
  }
  ConvertAnyTimeZoneToCurrentZone(
    pStrDate:
      | momentZone.Moment
      | Date
      | string
      | number
      | (number | string)[]
      | momentZone.MomentInputObject
      | null
      | undefined,
    pStrTimeZone: string,
  ) {
    momentZone.tz.setDefault(pStrTimeZone);
    return momentZone.parseZone(pStrDate).local(true).toDate();
  }
  ConvertAnyTimeZoneToCurrentZoneDbFormat(
    pStrDate:
      | momentZone.Moment
      | Date
      | string
      | number
      | (number | string)[]
      | momentZone.MomentInputObject
      | null
      | undefined,
    pStrTimeZone: string,
  ) {
    momentZone.tz.setDefault(pStrTimeZone);
    return momentZone(momentZone.parseZone(pStrDate).local(true).toDate())
      .tz(pStrTimeZone)
      .format();
  }
  AddDateHourFormat(
    pStrDate:
      | momentZone.Moment
      | Date
      | string
      | number
      | (number | string)[]
      | momentZone.MomentInputObject
      | null
      | undefined,
    pStrTimeZone: string,
    pStrDuration: momentZone.DurationInputArg1,
    pUnit: momentZone.DurationInputArg2,
  ) {
    return momentZone(pStrDate).add(pStrDuration, pUnit);
  }
  ConvertDateDDFormat(
    pStrDate:
      | momentZone.Moment
      | Date
      | string
      | number
      | (number | string)[]
      | momentZone.MomentInputObject
      | null
      | undefined,
    pStrTimeZone: string,
    pFormat: string,
  ) {
    momentZone.tz.setDefault(pStrTimeZone);
    return momentZone(pStrDate).tz(pStrTimeZone).format(pFormat);
  }
  convertStringUtcTimeZone(
    pStrDate:
      | momentZone.Moment
      | Date
      | string
      | number
      | (number | string)[]
      | momentZone.MomentInputObject
      | null
      | undefined,
    pStrTimeZone: string,
  ) {
    momentZone.tz.setDefault(pStrTimeZone);
    return momentZone.utc(pStrDate).format();
  }
  ConvertAnyTimeZoneToParseTimeZone(
    pStrDate:
      | momentZone.Moment
      | Date
      | string
      | number
      | (number | string)[]
      | momentZone.MomentInputObject
      | null
      | undefined,
    pStrTimeZone: string,
  ) {
    momentZone.tz.setDefault(pStrTimeZone);
    return momentZone
      .parseZone(momentZone(pStrDate).tz(pStrTimeZone).format())
      .local()
      .toDate();
  }
  convertAnyTimeZoneUtcTimeZone(
    pStrDate:
      | momentZone.Moment
      | Date
      | string
      | number
      | (number | string)[]
      | momentZone.MomentInputObject
      | null
      | undefined,
    pStrTimeZone: string,
  ) {
    momentZone.tz.setDefault(pStrTimeZone);
    return momentZone
      .utc(momentZone(pStrDate).tz(pStrTimeZone).format())
      .format();
  }
  convertLocalTimeZoneUtcTimeZone(
    pStrDate:
      | momentZone.Moment
      | Date
      | string
      | number
      | (number | string)[]
      | momentZone.MomentInputObject
      | null
      | undefined,
    pStrTimeZone: string,
    pStrTimeValue: string,
  ) {
    momentZone.tz.setDefault(pStrTimeZone);

    return momentZone
      .utc(pStrDate)
      .utcOffset(pStrTimeValue, true)
      .utc()
      .format();
  }
  convertAnyTime24Hour(pConvertTime) {
    return momentZone(pConvertTime, ['h:mm A']).format('HH:mm');
  }
  convertUniDateFormat(pConvertTime) {
    return momentZone(pConvertTime, 'DDMMYYYY').format('YYYY-MM-DD');
  }

  compareAuditHist(oldAuditValueArray, newAuditValueArray) {
    let audithist = '<ul>';
    let changedetect = false;
    for (const key in oldAuditValueArray) {
      if (Array.isArray(oldAuditValueArray[key])) {
        if (
          JSON.stringify(oldAuditValueArray[key]) !=
          JSON.stringify(newAuditValueArray[key])
        ) {
          audithist +=
            '<li>' +
            key +
            (newAuditValueArray[key][0].value == 1 ||
            newAuditValueArray[key][0].value == true
              ? ' selected'
              : ' unselected') +
            '</li>';
          changedetect = true;
        }
      } else if (oldAuditValueArray[key] != newAuditValueArray[key]) {
        if (
          (oldAuditValueArray[key] == '' || oldAuditValueArray[key] == null) &&
          (newAuditValueArray[key] != '' || newAuditValueArray[key] != null)
        )
          audithist +=
            '<li>' + key + ' added ' + newAuditValueArray[key] + '</li>';
        else if (
          (newAuditValueArray[key] == '' || newAuditValueArray[key] == null) &&
          (oldAuditValueArray[key] != '' || oldAuditValueArray[key] != null)
        )
          audithist +=
            '<li>' + key + ' removed ' + oldAuditValueArray[key] + '</li>';
        else
          audithist +=
            '<li>' +
            key +
            ' changed from ' +
            oldAuditValueArray[key] +
            ' to ' +
            newAuditValueArray[key] +
            '</li>';

        changedetect = true;
      }
    }
    if (changedetect) return audithist + '</ul>';
    else return '';
  }
  pasdec(desc) {
    return this.crypto.dec(desc);
  }
}
