import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { environment } from '../../../environments/environment';
import { CryptoService } from '../crypto/crypto.service';

@Injectable({
  providedIn: 'root',
})
export class PracticeService {
  constructor(
    public http: Http,
    private crypto: CryptoService,
  ) {}
  //start Practicetest App
  async AddPracticeAppQuestion(
    free_paid,
    test_id,
    title,
    first_choice,
    second_choice,
    third_choice,
    fourth_choice,
    answer,
    type,
    d_id,
    sd_id,
    topic_id,
    certi_id,
    cource_id,
    explanation,
    token,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          free_paid: free_paid,
          test_id: '',
          title: title,
          first_choice: first_choice,
          second_choice: second_choice,
          third_choice: third_choice,
          fourth_choice: fourth_choice,
          answer: answer,
          type: type,
          d_id: d_id,
          sd_id: sd_id,
          topic_id: topic_id,
          ref_link: '',
          youtube_link: '',
          bookpage_no: '',
          blog_url: '',
          status: '0',
          certi_id: certi_id,
          cource_id: cource_id,
          explanation: explanation,
        }),
      ),
    };
    // 
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.PRACTICE_APP_QUE_ADD;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async UpdatePracticeAppQuestion(
    free_paid,
    test_id,
    title,
    first_choice,
    second_choice,
    third_choice,
    fourth_choice,
    answer,
    type,
    d_id,
    sd_id,
    topic_id,
    id,
    certi_id,
    cource_id,
    explanation,
    token,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          free_paid: free_paid,
          test_id: '',
          title: title,
          first_choice: first_choice,
          second_choice: second_choice,
          third_choice: third_choice,
          fourth_choice: fourth_choice,
          answer: answer,
          type: type,
          d_id: d_id,
          sd_id: sd_id,
          topic_id: topic_id,
          ref_link: '',
          youtube_link: '',
          bookpage_no: '',
          blog_url: '',
          status: '0',
          certi_id: certi_id,
          cource_id: cource_id,
          explanation: explanation,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url =
        environment.BASE_URL +
        environment.PRACTICE_APP_QUE +
        this.crypto.enc(id);
      this.http.put(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async DeletePracticeAppQuestion(id, token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url =
        environment.BASE_URL +
        environment.PRACTICE_APP_QUE +
        this.crypto.enc(id);
      this.http.delete(url, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async ListPracticeAppQuestion(token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.PRACTICE_APP_QUE_ALL;
      this.http.post(url, options, { headers: headers }).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
          console.log(err);
        },
      );
    });
  }
  async IdPracticeAppQuestion(id, token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url =
        environment.BASE_URL +
        environment.PRACTICE_APP_QUE +
        this.crypto.enc(id);
      this.http.get(url, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  //End PracticeQueApp

  //start feed back
  async Feedbacklist(token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.FEEDBACKLIST;
      this.http.post(url, options, { headers: headers }).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
          console.log(err);
        },
      );
    });
  }
  //End Feedback

  //start Feedback delete
  async Deletefeddback(id, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          id: id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.FEEDBACK_DETELE;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  //END Feedback delete

  //start Feedback delete
  async Updatefeddback(id, addressed, notes, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          id: id,
          addressed: addressed,
          notes: notes,
        }),
      ),
    };

    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.FEEDBACK_UPDATE;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  //END Feedback delete

  //start Practice QUE
  async AddPracticeQuestion(
    test_id,
    title,
    first_choice,
    second_choice,
    third_choice,
    fourth_choice,
    answer,
    type,
    d_id,
    sd_id,
    topic_id,
    token,
    description,
    point,
    certi_id,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          test_id: test_id,
          title: title,
          first_choice: first_choice,
          second_choice: second_choice,
          third_choice: third_choice,
          fourth_choice: fourth_choice,
          answer: answer,
          type: type,
          d_id: d_id,
          sd_id: sd_id,
          topic_id: topic_id,
          ref_link: '',
          youtube_link: '',
          bookpage_no: '',
          blog_url: '',
          status: '0',
          description: description,
          point: parseInt(point),
          certi_id: certi_id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.PRACTICE_QUE_ADD;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async UpdatePracticeQuestion(
    test_id,
    title,
    first_choice,
    second_choice,
    third_choice,
    fourth_choice,
    answer,
    type,
    d_id,
    sd_id,
    topic_id,
    id,
    token,
    description,
    point,
    certi_id,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          test_id: test_id,
          title: title,
          first_choice: first_choice,
          second_choice: second_choice,
          third_choice: third_choice,
          fourth_choice: fourth_choice,
          answer: answer,
          type: type,
          d_id: d_id,
          sd_id: sd_id,
          topic_id: topic_id,
          ref_link: '',
          youtube_link: '',
          bookpage_no: '',
          blog_url: '',
          status: '0',
          description: description,
          point: parseInt(point),
          certi_id: certi_id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url =
        environment.BASE_URL + environment.PRACTICE_QUE + this.crypto.enc(id);
      this.http.put(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async DeletePracticeQuestion(id, token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url =
        environment.BASE_URL + environment.PRACTICE_QUE + this.crypto.enc(id);
      this.http.delete(url, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async ListPracticeQuestion(token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.PRACTICE_QUE_ALL;
      this.http.post(url, options, { headers: headers }).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve(err);
          console.log(err);
        },
      );
    });
  }
  async IdPracticeQuestion(id, token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url =
        environment.BASE_URL + environment.PRACTICE_QUE + this.crypto.enc(id);
      this.http.get(url, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  //End PracticeQue

  //start Practicetest
  async AddPracticeTest(
    practice_name,
    course_id,
    certi_id,
    token,
    acronums,
    time,
    type,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          practice_name: practice_name,
          status: '0',
          course_id: course_id,
          certi_id: certi_id,
          acronums: acronums,
          time: time,
          type: type,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.PRACTICE_TEST_ADD;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async UpdatePracticeTest(
    practice_name,
    id,
    course_id,
    certi_id,
    token,
    acronums,
    time,
    type,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          practice_name: practice_name,
          status: '0',
          course_id: course_id,
          certi_id: certi_id,
          acronums: acronums,
          time: time,
          type: type,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url =
        environment.BASE_URL + environment.PRACTICE_TEST + this.crypto.enc(id);
      this.http.put(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async DeletePracticeTest(id, token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url =
        environment.BASE_URL + environment.PRACTICE_TEST + this.crypto.enc(id);
      this.http.delete(url, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async ListPracticeTest(token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.PRACTICE_TEST_ALL;
      this.http.post(url, options, { headers: headers }).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve(err);
          console.log(err);
        },
      );
    });
  }
  async IdPracticeTest(id, token) {
    return new Promise((resolve) => {
      let headers = new Headers();

      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url =
        environment.BASE_URL + environment.PRACTICE_TEST + this.crypto.enc(id);
      this.http.get(url, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }

  async DomainFiltertestques(
    user_id: number,
    test_id: number,
    Course_id,
    token,
  ) {
    var cohort_id = localStorage.getItem('cohort_id');
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          user_id: user_id,
          test_id: test_id,
          course_id: Course_id,
          cohort_id: cohort_id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.PRECTICEANS_GETDOMAIN_REPORT;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async PracticeTestFiltertestques(
    user_id: number,
    test_id: number,
    cohort_id,
    token,
    RowNumber = 0,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          user_id: user_id,
          test_id: test_id,
          cohort_id: cohort_id,
          RowNumber: RowNumber,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.PRECTICEANS_FILTERS_QUEST;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  //End Practice test
}
