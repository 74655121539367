import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule ,APP_INITIALIZER} from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatInputModule } from '@angular/material/input';

import { RouterModule, Routes,Router } from '@angular/router';
import { LoginLayoutComponent } from './login-layout/login-layout.component';
import { HomeLayoutComponent } from './home-layout/home-layout.component';
import { LoginComponent } from './login/login.component';
import { NavigationComponent } from './navigation/navigation.component';
import { StudentComponent } from './pages/student/student.component';
import { FlashcardComponent } from './pages/flashcard/flashcard.component';
import { StudymaterialComponent } from './pages/studymaterial/studymaterial.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { CKEditorModule } from 'ckeditor4-angular';
import { CertificateComponent } from './pages/certificate/certificate.component';
import { CoursesComponent } from './pages/courses/courses.component';
import { CohortsComponent } from './pages/cohorts/cohorts.component';
import { DomainsComponent } from './pages/domains/domains.component';
import { SubdomainsComponent } from './pages/subdomains/subdomains.component';
import { TopicsComponent } from './pages/topics/topics.component';
import { SendmailComponent } from './pages/sendmail/sendmail.component';
import { PracticeappqueComponent } from './pages/practiceappque/practiceappque.component';
import { CreatepracticequeComponent } from './pages/practiceappque/createpracticeque/createpracticeque.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { ServicesService } from './services.service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { KnowlegeassComponent } from './pages/knowlegeass/knowlegeass.component';
import { KnowlegeassquesComponent } from './pages/knowlegeassques/knowlegeassques.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { PracticetestsComponent } from './pages/practicetests/practicetests.component';
import { PracticetestsqueComponent } from './pages/practicetests/practicetestsque/practicetestsque.component';
import { PracticequestionComponent } from './pages/practicequestion/practicequestion.component';
import { AuthorizationService } from './service/authorization.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { AddstudentcohortComponent } from './addstudentcohort/addstudentcohort.component';
import { CateexamtestComponent } from './pages/cateexamtest/cateexamtest.component';
import { CateexamtestquestionComponent } from './pages/cateexamtestquestion/cateexamtestquestion.component';
import { LivequestionComponent } from './pages/livequestion/livequestion.component';
import { AddlivequestionComponent } from './pages/livequestion/addlivequestion/addlivequestion.component';
import { AcronymsComponent } from './pages/acronyms/acronyms.component';
import { ApplicationVersionComponent } from './pages/application-version/application-version.component';
import { AdminlistComponent } from './pages/adminlist/adminlist.component';

import { VideolessonComponent } from './pages/videolesson/videolesson.component';

import { ScheduletestComponent } from './pages/scheduletest/scheduletest.component';
import { LiveresultComponent } from './pages/livequestion/liveresult/liveresult.component';
import { TeacherscreenComponent } from './pages/livequestion/teacherscreen/teacherscreen.component';
import { UserAnalysisComponent } from './user-analysis/user-analysis.component';
import { SubtopicsComponent } from './pages/subtopics/subtopics.component';
import { ClientcohortComponent } from './pages/clientcohort/clientcohort.component';
import { TemplatesComponent } from './pages/templates/templates.component';
import { AdminlogsComponent } from './pages/adminlogs/adminlogs.component';
import { ClientlogsComponent } from './pages/clientlogs/clientlogs.component';
import { ReferencesComponent } from './pages/references/references.component';
import { ManuallysendComponent } from './pages/manuallysend/manuallysend.component';
import { VariablesComponent } from './pages/variables/variables.component';
import { RecordingsComponent } from './pages/recordings/recordings.component';
import { MindmapsComponent } from './pages/mindmaps/mindmaps.component';
import { SupportComponent } from './pages/support/support.component';
import { BooksComponent } from './pages/books/books.component';
import { CompaniesComponent } from './pages/companies/companies.component';
import { TeachersComponent } from './pages/teachers/teachers.component';
import { GamificationComponent } from './pages/gamification/gamification.component';
import { NewchnagepassComponent } from './newchnagepass/newchnagepass.component';
import { FeedbackComponent } from './pages/feedback/feedback.component';
import { DiscussionComponent } from './pages/discussion/discussion.component';
import { UrlShortenerComponent } from './pages/url-shortener/url-shortener.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { MomentTimezonePickerModule } from 'moment-timezone-picker';
import { LivesessionComponent } from './pages/livesession/livesession.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { StudentreportComponent } from './pages/studentreport/studentreport.component';
import { QRCodeModule } from 'angularx-qrcode';
import { NgxKjuaModule } from 'ngx-kjua';
import { CohortreportComponent } from './pages/cohortreport/cohortreport.component';
import { LiveeventComponent } from './pages/liveevent/liveevent.component';
import { SelfpacedComponent } from './pages/selfpaced/selfpaced.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FailedemailsComponent } from './pages/failedemails/failedemails.component';
import { PaymentTransactionsComponent } from './pages/payment-transactions/payment-transactions.component';
import { MeetingsComponent } from './pages/meetings/meetings.component';
import { AssignedMaterialsComponent } from './pages/assigned-materials/assigned-materials.component';
import { JoinmeetingComponent } from './pages/joinmeeting/joinmeeting.component';
import { NgxEmojiPickerModule } from 'ngx-emoji-picker';
import { PaymentreceiptComponent } from './pages/paymentreceipt/paymentreceipt.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { AuthypasswordComponent } from './pages/authypassword/authypassword.component';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { SuccessemailsComponent } from './pages/successemails/successemails.component';
import { VerifyauthyComponent } from './verifyauthy/verifyauthy.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { StylePaginatorDirective } from './directive/style-paginator.directive';
import { HybridtemplatesComponent } from './pages/hybridtemplates/hybridtemplates.component';
import { HybridclassComponent } from './pages/hybridclass/hybridclass.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { ResetpassauthComponent } from './resetpassauth/resetpassauth.component';
import { StudentExamResultComponent } from './pages/student-exam-result/student-exam-result.component';
import { MiniMasterClassComponent } from './pages/mini-master-class/mini-master-class.component';
import { SelfpacedSummaryComponent } from './pages/selfpaced-summary/selfpaced-summary.component';
import { SelfpacedStudyProgressComponent } from './pages/selfpaced-study-progress/selfpaced-study-progress.component';
import { StudyTimeComponent } from './pages/study-time/study-time.component';
import * as Sentry from "@sentry/angular";

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    data: { title: 'First Component' },
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginLayoutComponent,
    data: { title: 'First Component' },
    children: [{ path: '', component: LoginComponent }],
  },
  {
    path: 'Changepassword/:email',
    component: NewchnagepassComponent,
    data: { title: 'First Component' },
    children: [{ path: '', component: NewchnagepassComponent }],
  },
  {
    path: 'reports/:id',
    component: StudentreportComponent,
    children: [{ path: '', component: StudentreportComponent }],
  },
  {
    path: 'result/:id',
    loadChildren: () =>
      import('./pages/student-exam-result/student-exam-result.module').then(
        (m) => m.StudentExamResultModule,
      ),
  },
  {
    path: 'test',
    loadChildren: () =>
      import(
        './pages/student-knowledge-result/student-knowledge-result.module'
      ).then((m) => m.StudentKnowledgeResultModule),
  },
  {
    path: 'paymentreceipt/:id',
    component: PaymentreceiptComponent,
    children: [{ path: '', component: PaymentreceiptComponent }],
  },
  {
    path: 'cohortreports/:id',
    component: CohortreportComponent,
    children: [{ path: '', component: CohortreportComponent }],
  },
  {
    path: 'joinmeeting/:id',
    component: JoinmeetingComponent,
    children: [{ path: '', component: JoinmeetingComponent }],
  },
  {
    path: 'selfpaced-summary/:id',
    component: SelfpacedSummaryComponent,
  },
  {
    path: 'selfpaced-study-progress/:id',
    component: SelfpacedStudyProgressComponent,
  },

  { path: 'authypassword/:data', component: AuthypasswordComponent },
  { path: 'verifyauthy/:data', component: VerifyauthyComponent },
  { path: 'forgotpassword', component: ForgotpasswordComponent },
  { path: 'resetpassauth/:email', component: ResetpassauthComponent },
  {
    path: 'main',
    component: HomeLayoutComponent,
    children: [
      { path: '', redirectTo: 'first', pathMatch: 'full' },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'students', component: StudentComponent },
      { path: 'Admin', component: AdminlistComponent },

      { path: 'clients', component: ClientcohortComponent },

      { path: 'flashcard', component: FlashcardComponent },
      { path: 'studymaterial', component: StudymaterialComponent },
      { path: 'certificate', component: CertificateComponent },
      { path: 'courses', component: CoursesComponent },
      { path: 'liveclass', component: CohortsComponent },

      { path: 'templates', component: TemplatesComponent },

      { path: 'domains', component: DomainsComponent },
      { path: 'subdomains', component: SubdomainsComponent },

      { path: 'topics', component: TopicsComponent },

      { path: 'subtopics', component: SubtopicsComponent },

      { path: 'Knowlegeass', component: KnowlegeassComponent },

      { path: 'sendmail', component: SendmailComponent },
      { path: 'manuallysend', component: ManuallysendComponent },
      { path: 'variables', component: VariablesComponent },
      { path: 'recordings', component: RecordingsComponent },

      { path: 'meetings', component: MeetingsComponent },
      { path: 'class-materials', component: AssignedMaterialsComponent },

      { path: 'practiceappques', component: PracticeappqueComponent },
      {
        path: 'practiceappques/createpracticeappques',
        component: CreatepracticequeComponent,
      },

      {
        path: 'practiceappques/createpracticeappques/:id',
        component: CreatepracticequeComponent,
      },

      { path: 'knowlegeassques', component: KnowlegeassquesComponent },
      {
        path: 'knowlegeassques/:id',
        component: KnowlegeassquesComponent,
      },
      { path: 'Practicetest', component: PracticetestsComponent },
      {
        path: 'Practicetest/createpractice',
        component: PracticetestsqueComponent,
      },
      {
        path: 'Practicetest/createpractice/:id',
        component: PracticetestsqueComponent,
      },

      { path: 'PracticetestQuestion', component: PracticequestionComponent },
      {
        path: 'PracticetestQuestion/:id',
        component: PracticequestionComponent,
      },

      { path: 'acronyms', component: AcronymsComponent },
      { path: 'application-version', component: ApplicationVersionComponent },

      { path: 'cohorts/Addstudent/:id', component: AddstudentcohortComponent },

      { path: 'CateExamTest', component: CateexamtestComponent },

      { path: 'CateExamQuestion', component: CateexamtestquestionComponent },
      {
        path: 'CateExamQuestion/:id',
        component: CateexamtestquestionComponent,
      },

      { path: 'livequestion', component: LivequestionComponent },
      {
        path: 'livequestion/addlivequestion/:id',
        component: AddlivequestionComponent,
      },
      { path: 'livequestion/liveresult/:id', component: LiveresultComponent },
      { path: 'livequestion/teacher/:id', component: TeacherscreenComponent },

      { path: 'livesession', component: LivesessionComponent },

      { path: 'videolesson', component: VideolessonComponent },
      { path: 'scheduletest', component: ScheduletestComponent },

      { path: 'students/useranalysis/:id', component: UserAnalysisComponent },

      { path: 'subtopics', component: SubtopicsComponent },

      { path: 'adminlogs', component: AdminlogsComponent },
      { path: 'clientlogs', component: ClientlogsComponent },

      { path: 'references', component: ReferencesComponent },
      { path: 'references/:id', component: ReferencesComponent },
      { path: 'mindmaps', component: MindmapsComponent },

      { path: 'support', component: SupportComponent },
      { path: 'books', component: BooksComponent },

      { path: 'companies', component: CompaniesComponent },
      { path: 'teachers', component: TeachersComponent },
      { path: 'gamification', component: GamificationComponent },
      { path: 'discussion', component: DiscussionComponent },
      { path: 'reports', component: ReportsComponent },
      { path: 'urlshortener', component: UrlShortenerComponent },
      { path: 'feedback', component: FeedbackComponent },
      { path: 'liveevent', component: LiveeventComponent },
      { path: 'selfpaced', component: SelfpacedComponent },
      { path: 'failedemails', component: FailedemailsComponent },
      { path: 'payment-transactions', component: PaymentTransactionsComponent },
      { path: 'successemails', component: SuccessemailsComponent },
      { path: 'hybridtemplates', component: HybridtemplatesComponent },
      { path: 'hybridclass', component: HybridclassComponent },
      { path: 'mini-master-class', component: MiniMasterClassComponent },
      { path: 'study-time', component: StudyTimeComponent },
      { path: '**', component: AppComponent },
    ],
  },
];

@NgModule({
  declarations: [
    AppComponent,
    LoginLayoutComponent,
    HomeLayoutComponent,
    LoginComponent,
    NavigationComponent,
    StudentComponent,
    FlashcardComponent,
    StudymaterialComponent,
    CertificateComponent,
    CoursesComponent,
    CohortsComponent,
    DomainsComponent,
    SubdomainsComponent,
    TopicsComponent,
    SendmailComponent,
    PracticeappqueComponent,
    CreatepracticequeComponent,
    DashboardComponent,
    KnowlegeassComponent,
    KnowlegeassquesComponent,
    PracticetestsComponent,
    PracticetestsqueComponent,
    PracticequestionComponent,
    AddstudentcohortComponent,
    CateexamtestComponent,
    CateexamtestquestionComponent,
    LivequestionComponent,
    AddlivequestionComponent,
    LiveresultComponent,
    TeacherscreenComponent,
    AddlivequestionComponent,
    AcronymsComponent,
    AdminlistComponent,
    VideolessonComponent,
    ScheduletestComponent,
    UserAnalysisComponent,
    SubtopicsComponent,
    ClientcohortComponent,
    TemplatesComponent,
    AdminlogsComponent,
    ClientlogsComponent,
    ReferencesComponent,
    ManuallysendComponent,
    VariablesComponent,
    RecordingsComponent,
    MindmapsComponent,
    SupportComponent,
    BooksComponent,
    CompaniesComponent,
    TeachersComponent,
    GamificationComponent,
    NewchnagepassComponent,
    FeedbackComponent,
    DiscussionComponent,
    UrlShortenerComponent,
    ReportsComponent,
    LivesessionComponent,
    StudentreportComponent,
    CohortreportComponent,
    LiveeventComponent,
    SelfpacedComponent,
    FailedemailsComponent,
    PaymentTransactionsComponent,
    MeetingsComponent,
    AssignedMaterialsComponent,
    JoinmeetingComponent,
    PaymentreceiptComponent,
    AuthypasswordComponent,
    SuccessemailsComponent,
    VerifyauthyComponent,
    StylePaginatorDirective,
    HybridtemplatesComponent,
    HybridclassComponent,
    ForgotpasswordComponent,
    ResetpassauthComponent,
    MiniMasterClassComponent,
    SelfpacedSummaryComponent,
    SelfpacedStudyProgressComponent,
    ApplicationVersionComponent,
    StudyTimeComponent,
    // StudentExamResultComponent
  ],
  imports: [
    DragDropModule,
    QRCodeModule,
    NgxQRCodeModule,
    NgxKjuaModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatPaginatorModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatInputModule,
    MatSidenavModule,
    MatListModule,
    MatButtonModule,
    MatIconModule,
    CKEditorModule,
    HttpClientModule,
    HttpModule,
    NgxSpinnerModule,
    NgSelectModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatSortModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MomentTimezonePickerModule,
    NgxEmojiPickerModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-bottom-right',
      closeButton: true,
    }),
    RouterModule.forRoot(appRoutes),
    NgxDropzoneModule,
    NgOtpInputModule,
    InfiniteScrollModule,
  ],
  providers: [
    ServicesService,
    AuthorizationService,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
